<template>
	<section class="container-fluid mt-3">
		<div class="row justify-content-center">
			<div class="col-lg-10 col-12">
				<div class="row justify-content-center">
					<template v-if="$can('Add', 'Company', 'Employees')">
						<div class="col-12">
							<h5 class="text-center">Rådgiver</h5>

							<!-- Add this line -->
							<hr />
							<!-- Add this line -->
						</div>
						<div class="col-auto mt-1">
							<router-link
								:to="{
									name: 'CompanyEmployeeSet',
									params: { companyId: companyId },
								}"
							>
								<b-button variant="primary"> <font-awesome-icon icon="user-plus" /> Tilføj eksisterende bruger </b-button>
							</router-link>
						</div>
						<div class="col-auto mt-1" v-if="$can('Import', 'Company', 'Employees')">
							<router-link
								:to="{
									name: 'CompanyEmployeeAddAdmin',
									params: { companyId: companyId },
								}"
							>
								<b-button class="btn-primary"> <font-awesome-icon icon="user-plus" /> Opret medarbejder </b-button>
							</router-link>
						</div>
						<div class="col-auto mt-1" v-if="$can('Import', 'Company', 'Employees')">
							<router-link
								:to="{
									name: 'CompanyEmployeeImport',
									params: { companyId: companyId },
								}"
							>
								<b-button>
									<font-awesome-icon icon="user-plus" />
									{{ $t('employeeList.import') }}
								</b-button>
							</router-link>
						</div>
						<div class="col-auto mt-1" v-if="$can('Import', 'Company', 'Employees')">
							<xlsx-workbook>
								<xlsx-sheet :collection="sheet.data" v-for="sheet in getExcelFormattedData" :key="sheet.name" :sheet-name="sheet.name" />
								<xlsx-download :filename="getFileName">
									<b-button>
										<font-awesome-icon icon="download" />
										Download medarbejderlisten for admin/rådgiver</b-button
									>
								</xlsx-download>
							</xlsx-workbook>
						</div>
						<div class="col-12">
							<!-- Add this line -->
							<hr />
							<!-- Add this line -->
						</div>
					</template>
				</div>
				<br />
				<div class="row justify-content-center">
					<div class="col-12">
						<h5 class="text-center">Virksomhedsadmin</h5>
						<!-- Add this line -->
						<hr />
						<!-- Add this line -->
					</div>
					<div class="col-auto mt-1">
						<b-button class="mb-3" @click="addOrRemoveEmployee"> Tilmeld/frameld medarbejder </b-button>
					</div>
					<div class="col-auto mt-1">
						<b-button class="mb-3" @click="editEmployee">Løn regulering og ændring</b-button>
					</div>
					<div class="col-auto mt-1">
						<b-button class="mb-3" @click="editEmployeeSickAbsence"> Sygdom og orlov</b-button>
					</div>
					<div class="col-auto mt-1">
						<xlsx-workbook>
							<xlsx-sheet :collection="sheet.data" v-for="sheet in getExcelFormattedDataForCompanyAdmin" :key="sheet.name" :sheet-name="sheet.name" />
							<xlsx-download :filename="getFileName">
								<b-button>Download medarbejderelisten</b-button>
							</xlsx-download>
						</xlsx-workbook>
						<!-- <b-button @click="exportEmployees">
						{{ $t('employeeList.export') }}
					</b-button> -->
					</div>
				</div>
			</div>
			<div class="col-12">
				<b-form-input class="input col-12" v-model="globalfilter" placeholder="Søg" />

				<BaseActionButton v-on:on-click="changeCalcuationType" type="button" id="signupButton" class="mt-1">
					{{ calucationType }}
				</BaseActionButton>
				<div class="row">
					<div class="col-12">
						<BaseTable
							@currentPageChanged="currentPageChanged"
							@perPageChanged="perPageChanged"
							:items="companyEmployeesFormatted"
							:fields="roleFields"
							:isBusy="isBusy"
							:func="myCompare"
							:sortBy="'groupName'"
							:sortDesc="false"
						>
							<template #thead-top="data">
								<b-tr>
									<b-th colspan="11"></b-th>
									<b-th variant="primary" colspan="2" class="text-center"> {{ $t('employeeList.table.totalCompanyShare') }}</b-th>
									<b-th variant="secondary" colspan="2" class="text-center"> {{ $t('employeeList.table.totalEmployeeShare') }}</b-th>
									<b-th variant="success" colspan="0" class="text-center"> Samlet bidrag </b-th>
								</b-tr>
							</template>

							<template v-slot:head()="data">
								<template v-if="data.field.center == true">
									<span v-b-tooltip.hover :title="data.field.tooltip" style="display: flex; justify-content: center; align-items: center">
										{{ data.label }}
									</span></template
								>
								<template v-else>
									<span v-b-tooltip.hover :title="data.field.tooltip">
										{{ data.label }}
									</span>
								</template>
							</template>

							<template v-slot:cell(index)="data">
								{{ currentPage * perPage - perPage + data.index + 1 }}
							</template>
							<template v-slot:cell(user.fullName)="data">
								<router-link
									:to="{
										name: 'CompanyEmployeeInfo',
										params: { companyId: companyId, employeeId: data.item.user.id, policyId: data.item.policyId },
									}"
								>
									<p :title="data.item.user.fullName">{{ data.item.user.fullName }}</p>
								</router-link>
							</template>
							<template v-slot:cell(advisorNote)="data">
								<template v-if="getUserPensions(data.item.user).length > 1">
									<span v-b-tooltip.hover title="Seperate pensionsaftale">
										<b-button size="sm" @click="data.toggleDetails" class="mr-2"> {{ data.detailsShowing ? '-' : '+' }}</b-button>
									</span>
								</template>
								<template v-if="employeeIsResigned(data.item.user)">
									<span v-b-tooltip.hover :title="getResignation(data.item.user)">
										<font-awesome-icon :icon="['fa', 'user-slash']" class="icon fa-lg" style="color: red" />
									</span>
								</template>
								<template v-if="isAdmin || isAdvisor">
									<template v-if="getAdvisorNoteForEmployee(data.item.user) == 'Rådgivning'">
										<span v-b-tooltip.hover title="Rådgivning">
											<font-awesome-icon :icon="['fa', 'user-tie']" class="icon fa-lg" style="color: rgb(0, 0, 0)" />
										</span>
									</template>
									<template v-if="getAdvisorNoteForEmployee(data.item.user) == 'Underskrift'">
										<span v-b-tooltip.hover title="Underskrift">
											<font-awesome-icon :icon="['fa', 'clock']" class="icon fa-lg" style="color: rgba(37, 204, 162, 0.493)" />
										</span>
									</template>
									<template v-if="getAdvisorNoteForEmployee(data.item.user) == 'Antagelse'">
										<span v-b-tooltip.hover title="Antagelse">
											<font-awesome-icon :icon="['fa', 'thermometer']" class="icon fa-lg" style="color: rgba(97, 47, 47, 0.493)" />
										</span>
									</template>
									<template v-if="getAdvisorNoteForEmployee(data.item.user) == 'Afkald fuld'">
										<span v-b-tooltip.hover title="Afkald fuld">
											<font-awesome-icon :icon="['fa', 'ban']" class="icon fa-lg" style="color: red" />
										</span>
									</template>
									<template v-if="getAdvisorNoteForEmployee(data.item.user) == 'Afkald delvis'">
										<span v-b-tooltip.hover title="Afkald delvis">
											<font-awesome-icon :icon="['fa', 'ban']" class="icon fa-lg" style="color: rgb(206, 206, 72)" />
										</span>
									</template>
									<template v-if="getAdvisorNoteForEmployee(data.item.user) == 'Ok'">
										<span v-b-tooltip.hover title="Ok">
											<font-awesome-icon :icon="['fa', 'check']" class="icon fa-lg" style="color: green" />
										</span>
									</template>
								</template>
							</template>
							<template #row-details="data">
								<b-card>
									<li v-for="(pension, index) in getUserPensions(data.item.user)" :key="pension.id" v-if="index > 0">
										<b>Ekstern pensionsaftale:</b> {{ getPensionSupplier(pension.pensionId) }}
									</li>
								</b-card>
							</template>
							<template v-slot:cell(user.cpr)="data">
								<p :title="data.item.user.cpr">{{ data.item.user.cpr }}</p>
							</template>
							<template v-slot:cell(position)="data">
								<p :title="data.item.position">{{ data.item.position }}</p>
							</template>
							<template v-slot:cell(employmentDate)="data">
								<p :title="getEmploymentDate(data.item.user)">{{ getEmploymentDateShort(data.item.user) }}</p>
							</template>
							<template v-slot:cell(primarySupplier)="data">
								<p :title="getPrimarySupplier(data.item.user)">{{ getPrimarySupplier(data.item.user) }}</p>
							</template>
							<template v-slot:cell(healthInsuranceStartDate)="data">
								<p :title="getHealthInsuranceDate(data.item.user)">{{ getHealthInsuranceDateShort(data.item.user) }}</p>
							</template>
							<template v-slot:cell(pensionStartDate)="data">
								<p :title="getPensionDate(data.item.user)">{{ getPensionDateShort(data.item.user) }}</p>
							</template>
							<template v-slot:cell(salary)="data">
								<template v-if="canReadSalary(data.item)">
									<p :title="data.item.salary">{{ data.item.salary }}</p>
								</template>
								<template v-else> - </template>
							</template>

							<template v-slot:cell(company.total)="data">
								<template v-if="canReadSalary(data.item, calucationType)">
									<p :title="$t('global.total')" class="d-inline-block mr-2">
										<u>
											<b :title="numberFormat(getTotalCompanyShare(data.item.user)) + calucationType"
												>{{ numberFormat(getTotalCompanyShare(data.item.user)) }} {{ calucationType }}</b
											>
										</u>
									</p>
								</template>
								<template v-else>
									<p :title="$t('global.total')" class="d-inline-block mr-2">
										{{ $t('global.total') }} <br />
										<u
											><b> - {{ calucationType }}</b></u
										>
									</p>
								</template>
							</template>

							<template v-slot:cell(company.mandatory)="data">
								<template v-if="canReadSalary(data.item, calucationType)">
									<p :title="$t('global.mandatory')" class="d-inline-block mr-2">
										<b :title="numberFormat(getMandatoryCompanyShare(data.item.user)) + calucationType"
											>{{ numberFormat(getMandatoryCompanyShare(data.item.user)) }} {{ calucationType }}</b
										>
									</p>
								</template>
								<template v-else>
									<p :title="$t('global.mandatory')" class="d-inline-block mr-2">
										{{ $t('global.abbreviations.mandatory') }}<br /><b class="green"> - {{ calucationType }}</b>
									</p>
								</template>
							</template>

							<template v-slot:cell(company.voluntary)="data">
								<template v-if="canReadSalary(data.item, calucationType)">
									<p :title="$t('global.voluntary')" class="d-inline-block mr-2">
										<b :title="numberFormat(getVoluntaryCompanyShare(data.item.user)) + calucationType" class="green"
											>{{ numberFormat(getVoluntaryCompanyShare(data.item.user)) }} {{ calucationType }}</b
										>
									</p>
								</template>
								<template v-else>
									<p :title="$t('global.voluntary')" class="d-inline-block mr-2">
										{{ $t('global.voluntary') }}<br /><b class="green"> - {{ calucationType }}</b>
									</p>
								</template>
							</template>

							<template v-slot:cell(employee.total)="data">
								<template v-if="canReadSalary(data.item, calucationType)">
									<p :title="$t('global.total')" class="d-inline-block mr-2">
										<u
											><b :title="numberFormat(getTotalEmployeeShare(data.item.user)) + calucationType"
												>{{ numberFormat(getTotalEmployeeShare(data.item.user)) }} {{ calucationType }}</b
											></u
										>
									</p>
								</template>
								<template v-else>
									<p :title="$t('global.total')" class="d-inline-block mr-2">
										{{ $t('global.total') }} <br />
										<u
											><b>- {{ calucationType }}</b></u
										>
									</p>
								</template>
							</template>

							<template v-slot:cell(employee.mandatory)="data">
								<template v-if="canReadSalary(data.item, calucationType)">
									<p :title="$t('global.mandatory')" class="d-inline-block mr-2">
										<b :title="numberFormat(getMandatoryEmployeeShare(data.item.user)) + calucationType"
											>{{ numberFormat(getMandatoryEmployeeShare(data.item.user)) }} {{ calucationType }}</b
										>
									</p>
								</template>
								<template v-else>
									<p :title="$t('global.voluntary')" class="d-inline-block mr-2">
										{{ $t('global.voluntary') }}<br /><b class="green"> - {{ calucationType }}</b>
									</p>
								</template>
							</template>

							<template v-slot:cell(employee.voluntary)="data">
								<template v-if="canReadSalary(data.item, calucationType)">
									<p :title="$t('global.voluntary')" class="d-inline-block mr-2">
										<b :title="numberFormat(getVoluntaryEmployeeShare(data.item.user)) + calucationType" class="green"
											>{{ numberFormat(getVoluntaryEmployeeShare(data.item.user)) }} {{ calucationType }}</b
										>
									</p>
								</template>
								<template v-else>
									<p :title="$t('global.voluntary')" class="d-inline-block mr-2">
										{{ $t('global.voluntary') }} <br />
										<b class="green">- {{ calucationType }}</b>
									</p>
								</template>
							</template>

							<template v-slot:cell(pensionContribution)="data">
								<template v-if="canReadSalary(data.item, calucationType)">
									<u :title="numberFormat(getTotalPension(data.item.user)) + calucationType">
										{{ numberFormat(getTotalPension(data.item.user)) }} {{ calucationType }}
									</u>
								</template>
								<template v-else> - </template>
							</template>
						</BaseTable>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
import Swal from 'sweetalert2'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'

export default {
	name: 'CompanyEmployeeList',
	mixins: [dateMixin],
	components: {
		BaseTable,
		XlsxDownload,
		XlsxWorkbook,
		XlsxSheet,
	},
	data() {
		return {
			currentPage: 1,
			perPage: 30,
			sortBy: 'user.fullName',
			globalfilter: '',
			calucationType: '%',
			fields: [
				{
					key: 'index',
					label: '',
					tooltip: 'Indeks',
				},
				// {
				// 	key: 'show_details',
				// 	label: '',
				// 	tooltip: 'Seperate pensionsaftaler',
				// },
				{
					key: 'advisorNote',
					label: 'Status',
				},
				{
					key: 'user.fullName',
					label: i18n.t('employeeList.table.name'),
					tooltip: i18n.t('employeeList.table.name'),
					sortable: true,
				},
				{
					key: 'groupName',
					label: i18n.t('employeeList.table.group'),
					tooltip: i18n.t('employeeList.table.group'),
					sortable: true,
				},
				{
					key: 'employmentDate',
					label: i18n.t('global.abbreviations.employmentDate'),
					tooltip: 'Ansættelsedato',
					sortable: true,
				},
				{
					key: 'pensionStartDate',
					label: i18n.t('global.abbreviations.pensionStartDate'),
					tooltip: 'Pension startdato',
					sortable: true,
				},
				{
					key: 'healthInsuranceStartDate',
					label: i18n.t('global.abbreviations.healthInsuranceStartDate'),
					tooltip: 'Sundhedsforsikring startdato',
					sortable: true,
				},
				{
					key: 'position',
					label: i18n.t('employeeList.table.position'),
					tooltip: i18n.t('employeeList.table.position'),
					sortable: true,
				},
				{
					key: 'user.cpr',
					label: i18n.t('employeeList.table.cpr'),
					tooltip: 'Medarbejderens cpr',
					sortable: true,
				},
				{
					key: 'salary',
					label: i18n.t('employeeList.table.salary'),
					tooltip: i18n.t('employeeList.table.salary'),
					sortable: true,
				},
				{
					key: 'primarySupplier',
					label: i18n.t('global.abbreviations.mainSupplier'),
					tooltip: i18n.t('employeeList.table.mainSupplier'),
					sortable: false,
				},
				/* {
					key: 'company.total',
					label: i18n.t('global.total'),
					tooltip: i18n.t('global.total'),
					sortable: false,
				},*/
				{
					key: 'company.mandatory',
					label: i18n.t('global.abbreviations.mandatory'),
					tooltip: i18n.t('global.mandatory'),
					sortable: false,
					center: true,
					thClass: 'text-center',
					tdClass: 'text-center',
				},
				{
					key: 'company.voluntary',
					label: i18n.t('global.abbreviations.voluntary'),
					tooltip: i18n.t('global.voluntary'),
					sortable: false,
					center: true,
					thClass: 'text-center',
					tdClass: 'text-center',
				},
				/*
				{
					key: 'employee.total',
					label: i18n.t('global.total'),
					tooltip: i18n.t('global.total'),
					sortable: false,
				},
				*/
				{
					key: 'employee.mandatory',
					label: i18n.t('global.abbreviations.mandatory'),
					tooltip: i18n.t('global.mandatory'),
					sortable: false,
					center: true,
					thClass: 'text-center',
					tdClass: 'text-center',
				},
				{
					key: 'employee.voluntary',
					label: i18n.t('global.abbreviations.voluntary'),
					tooltip: i18n.t('global.voluntary'),
					sortable: false,
					center: true,
					thClass: 'text-center',
					tdClass: 'text-center',
				},
				{
					key: 'pensionContribution',
					label: 'Total',
					tooltip: i18n.t('employeeList.table.pensionContribution'),
					sortable: false,
					center: true,
					thClass: 'text-center',
					tdClass: 'text-center',
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		myCompare(a, b, key) {
			if (key === 'healthInsuranceStartDate' || key === 'pensionStartDate' || key === 'employmentDate') {
				/*	// if a is "-" and b is not "-"
					if (a[key] === '-' && b[key] !== '-') {
						return 1
					}
					// if a is not "-" and b is "-"
					else if (a[key] !== '-' && b[key] === '-') {
						return -1
					}
					// if a and b are "-"
					else if (a[key] === '-' && b[key] === '-') {
						return 0
					} else if (a[key] !== '-' && b[key] !== '-') {
						// convert to date
						var dateA = new Date(a[key])
						console.log(dateA);
						var dateB = new Date(b[key])
						// compare
						if (dateA > dateB) {
							return 1
						} else if (dateA < dateB) {
							return -1
						} else {
							return 0
						}
					} */

				if (a[key] === '-' && b[key] !== '-') {
					return 1
				} else if (a[key] !== '-' && b[key] === '-') {
					return -1
				} else if (a[key] === '-' && b[key] === '-') {
					return 0
				} else if (a[key] !== '-' && b[key] !== '-') {
					// convert to date
					var dateA = new Date(a[key])
					var dateB = new Date(b[key])
					// compare
					return dateA - dateB
				}
			} else {
				// Let b-table handle sorting other fields (other than `date` field)
				return false
			}
		},
		getResignation(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var date = employeePolicy?.employeeCondition?.resignationDate
			return date ? 'Fratrådt d. ' + date.toLocaleDateString('da-DK') : 'Fratrådt d. -'
		},
		getHealthInsuranceDate(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var options = { year: 'numeric', month: 'long', day: 'numeric' }
			return employeePolicy?.employeeCondition?.healthInsuranceStartDate?.toLocaleDateString('da-DK', options) ?? '-'
		},
		getHealthInsuranceDateShort(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
			return employeePolicy?.employeeCondition?.healthInsuranceStartDate?.toLocaleDateString('da-DK', options) ?? '-'
		},
		getPensionDate(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var options = { year: 'numeric', month: 'long', day: 'numeric' }
			return employeePolicy?.employeeCondition?.pensionStartDate?.toLocaleDateString('da-DK', options) ?? '-'
		},
		getPensionDateShort(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
			return employeePolicy?.employeeCondition?.pensionStartDate?.toLocaleDateString('da-DK', options) ?? '-'
		},
		getEmploymentDate(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var options = { year: 'numeric', month: 'long', day: 'numeric' }
			return employeePolicy?.employeeCondition?.employmentDate?.toLocaleDateString('da-DK', options) ?? '-'
		},
		getEmploymentDateShort(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
			return employeePolicy?.employeeCondition?.employmentDate?.toLocaleDateString('da-DK', options) ?? '-'
		},
		currentPageChanged(page) {
			this.currentPage = page
		},
		perPageChanged(perPage) {
			this.perPage = perPage
		},
		numberFormatRound(number) {
			return Math.round(Number(number)).toLocaleString('da-DK')
		},
		async addOrRemoveEmployee() {
			Swal.fire({
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Tilmeld medarbejder',
				cancelButtonText: 'Frameld medarbejder',
			}).then((result) => {
				if (result.dismiss === 'esc' || result.dismiss == 'backdrop') {
					return
				} else if (result.isConfirmed) {
					this.$router.push({
						name: 'CompanyEmployeeAdd',
						params: {
							companyId: this.companyId,
						},
					})
				} else if (result.dismiss == 'cancel') {
					this.resignEmployee()
				}
			})
		},
		async resignEmployee() {
			Swal.fire({
				title: 'Fratræde medarbejderen',
				text: 'Indsæt cpr for medarbejderen',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off',
				},
				showCancelButton: true,
				confirmButtonText: 'Søg',
				showLoaderOnConfirm: true,
				preConfirm: (cpr) => {
					try {
						return this.findUserByCpr(cpr)
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`${error.message}`)
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			}).then((result) => {
				if (result.isConfirmed) {
					var employeeId = result.value.user.id
					var policyId = result.value.policyId
					this.$router.push({
						name: 'CompanyEmployeeResignation',
						params: { companyId: this.companyId, employeeId: employeeId, policyId: policyId},
						query: { editable: true },
					})
				}
			})
		},
		async editEmployee() {
			Swal.fire({
				title: 'Lønregulering og ændring',
				text: 'Indsæt cpr for medarbejderen',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off',
				},
				showCancelButton: true,
				confirmButtonText: 'Søg',
				showLoaderOnConfirm: true,
				preConfirm: (cpr) => {
					try {
						return this.findUserByCpr(cpr)
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`${error.message}`)
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			}).then((result) => {
				if (result.isConfirmed) {
					var employeeId = result.value.user.id
					var policyId = result.value.policyId
					this.$router.push({
						name: 'CompanyEmployeeCondition',
						params: { companyId: this.companyId, employeeId: employeeId, policyId: policyId},
						query: { editable: true },
					})
				}
			})
		},
		async editEmployeeSickAbsence() {
			Swal.fire({
				title: 'Lønregulering og ændring',
				text: 'Indsæt cpr for medarbejderen',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off',
				},
				showCancelButton: true,
				confirmButtonText: 'Søg',
				showLoaderOnConfirm: true,
				preConfirm: (cpr) => {
					try {
						return this.findUserByCpr(cpr)
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`${error.message}`)
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			}).then((result) => {
				if (result.isConfirmed) {
					var employeeId = result.value.user.id
					var policyId = result.value.policyId
					Swal.fire({
						title: 'Sygdom eller orlov',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#3085d6',
						confirmButtonText: 'Orlov',
						cancelButtonText: 'Sygdom',
					}).then((result) => {
						if (result.dismiss === 'esc' || result.dismiss == 'backdrop') {
							return
						} else if (result.isConfirmed) {
							this.$router.push({
								name: 'CompanyEmployeeAbsence',
								params: { companyId: this.companyId, employeeId: employeeId, policyId: policyId},
								query: { editable: true },
							})
						} else if (result.dismiss == 'cancel') {
							this.$router.push({
								name: 'CompanyEmployeeSickLeave',
								params: { companyId: this.companyId, employeeId: employeeId, policyId: policyId},
								query: { editable: true },
							})
						}
					})
				}
			})
		},
		findUserByCpr(cpr) {
			var user = this.companyEmployeesFormatted.find((employee) => {
				return employee.user.cpr == cpr
			})
			if (!user) {
				throw new Error('Der er ikke fundet en medarbejder med denne cpr')
			} else return user
		},
		changeCalcuationType() {
			this.calucationType = this.calucationType === ' kr.' ? '%' : ' kr.'
		},
		getUser(employeeId) {
			var employee = this.users.find((item) => {
				return item.id == employeeId
			})
			return employee
		},
		getCompanyPensionById(pensionId) {
			var companyPension = this.companyPensions.find((item) => {
				return item.id == pensionId
			})
			return companyPension?.pension ?? '??'
		},
		getCompanyInsuranceById(insuranceId) {
			var companyInsurance = this.companyInsurances.find((item) => {
				return item.id == insuranceId
			})
			return companyInsurance.insurance
		},

		getCpr(user) {
			return user?.cpr ?? ''
		},
		getEmail(employee) {
			return employee.email ?? ' '
		},
		getPosition(employee) {
			var employeePolicy = this.getEmployeePolicyForUser(employee?.id)
			return employeePolicy?.employeeCondition?.position ?? ''
		},
		getEmployeePolicyForUser(employeeId) {
			return (
				this.employeePolicies.find((item) => {
					return item.employeeId == employeeId
				}) ?? null
			)
		},
		getSalaryForTheUser(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			return employeePolicy?.employeeCondition?.salary ?? 0
		},
		getTotalPension(user) {
			var companyPension = this.getMandatoryCompanyShare(user)
			var employeePension = this.getMandatoryEmployeeShare(user)
			var voluntaryCompanyContribution = this.getVoluntaryEmployeeShare(user)
			var voluntaryEmployeeContribution = this.getVoluntaryCompanyShare(user)
			var totalPension = companyPension + employeePension + voluntaryCompanyContribution + voluntaryEmployeeContribution
			return totalPension
		},
		getTotalCompanyShare(user) {
			var mandatoryCompanyShare = this.getMandatoryCompanyShare(user)
			var voluntaryCompanyShare = this.getVoluntaryCompanyShare(user)
			return mandatoryCompanyShare + voluntaryCompanyShare
		},
		getTotalEmployeeShare(user) {
			var mandatoryEmployeeShare = this.getMandatoryEmployeeShare(user)
			var voluntaryEmployeeShare = this.getVoluntaryEmployeeShare(user)
			return mandatoryEmployeeShare + voluntaryEmployeeShare
		},
		getGroup(employee) {
			var employeeId = employee?.id
			var employeePolicy = this.getEmployeePolicyForUser(employeeId)
			var groupId = employeePolicy?.employeeCondition?.group ?? null
			if (groupId === undefined) {
				return ''
			}
			var groups = this.company.groups
			return groups.find((group) => {
				return group.id == groupId
			})
		},
		getPrimarySupplier(employee) {
			try {
				var employeePolicy = this.getEmployeePolicyForUser(employee.id)
				var pensionId = employeePolicy?.pensions[0]?.pensionId
				var companyPension = this.getCompanyPensionById(pensionId)
				var supplierId = companyPension.supplierId
				var supplierName = this.getSupplier(supplierId)?.name ?? '-'
				return supplierName
			} catch (e) {
				return '??'
			}
		},
		getPensionSupplier(pensionId) {
			var companyPension = this.getCompanyPensionById(pensionId)
			var supplierId = companyPension.supplierId
			var supplierName = this.getSupplier(supplierId)?.name ?? '-'
			return supplierName
		},
		getSupplier(supplierId) {
			var supplier = this.suppliers.find((item) => {
				return item.id == supplierId
			})
			return supplier
		},
		getGroupName(employee) {
			return this.getGroup(employee)?.name ?? '-'
		},
		getMandatoryEmployeeShare(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var pensionId = employeePolicy?.pensions[0]?.pensionId
			var companyPension = this.getCompanyPensionById(pensionId)
			var value = companyPension?.mandatoryEmployeeShare?.value ?? 0
			var type = companyPension.mandatoryEmployeeShare?.type ?? NaN
			var salary = this.getSalaryForTheUser(user)
			return this.calucate(value, salary, type)
		},
		getMandatoryCompanyShare(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var pensionId = employeePolicy?.pensions[0]?.pensionId
			var companyPension = this.getCompanyPensionById(pensionId)
			var value = companyPension?.mandatoryCompanyShare?.value ?? 0
			var type = companyPension?.mandatoryCompanyShare?.type ?? NaN
			var salary = this.getSalaryForTheUser(user)
			return this.calucate(value, salary, type)
		},
		getVoluntaryCompanyShare(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var salary = this.getSalaryForTheUser(user)
			var value = employeePolicy?.pensions[0]?.voluntaryCompanyContribution?.value ?? 0
			var type = employeePolicy?.pensions[0]?.voluntaryCompanyContribution?.type
			return this.calucate(value, salary, type)
		},
		getVoluntaryEmployeeShare(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)
			var salary = this.getSalaryForTheUser(user)
			var value = employeePolicy?.pensions[0]?.voluntaryEmployeeContribution?.value ?? 0
			var type = employeePolicy?.pensions[0]?.voluntaryEmployeeContribution?.type
			return this.calucate(value, salary, type)
		},
		calucate(value, salary, type) {
			if (this.calucationType === '%') {
				if (type == 'percentage') {
					return value
				} else {
					return (value / salary) * 100
				}
			} else if (this.calucationType === ' kr.') {
				if (type == 'percentage') {
					return (salary * value) / 100
				} else {
					return value
				}
			}
		},
		numberFormat(number) {
			if (this.calucationType === '%') {
				return Number(number).toLocaleString('da-DK')
			} else if (this.calucationType === ' kr.') {
				return Math.round(Number(number)).toLocaleString('da-DK')
			}
			return Number(number).toLocaleString('da-DK')
		},
		employeeIsResigned(employee) {
			var employeePolicy = this.getEmployeePolicyForUser(employee?.id)
			return employeePolicy?.employeeCondition?.resignationDate ?? false
		},
		canReadSalary(item, calucationType) {
			if (this.isAdmin || this.isAdvisor) {
				return true
			} else {
				var employeePolicy = this.getEmployeePolicyForUser(item?.user?.id)
				if (employeePolicy?.employeeCondition?.salaryVisible) {
					return true
				} else {
					if (calucationType == '%') {
						return true
					} else {
						return false
					}
				}
			}
		},
		getAdvisorNoteForEmployee(employee) {
			var employeePolicy = this.getEmployeePolicyForUser(employee?.id)
			return employeePolicy?.advisorNote ?? ''
		},
		getUserPensions(user) {
			var employeePolicy = this.getEmployeePolicyForUser(user?.id)

			return employeePolicy?.pensions ?? []
		},
	},
	computed: {
		roleFields() {
			if (this.isAdmin || this.isAdvisor) {
				return this.fields
			} else {
				return this.fields
				// return this.fields.filter((field) => {
				// 	return field.key !== 'advisorNote'
				// })
			}
		},
		currentPage() {
			return this.$refs?.table?.currentPage ?? 1
		},
		perPage() {
			return this.$refs?.table?.perPage ?? 1
		},
		getFileName() {
			var date = new Date()
			return this.company.companyInfo.name + '-' + date.toISOString() + '-medarbejderlisten.xlsx'
		},
		getExcelFormattedData() {
			console.log('getting datas')
			//CVR	Fornavn	Efternavn	Primær email (bruges til login)	Sekundær email	Landekode	Telefon	CPR-nr.	Stilling	Grundløn pr mnd	Tillægsløn pr mnd	Fri firmaandel (%)	Fri medarbejderandel (%)	Død (%)	Tab af arbejdsevne (%)	Visse kritiske sygdomme sum	Visse kritiske sygdomme børn sum	Børnerente (%)	Depotværdi	Sundhedsforsikring
			var data = this.companyEmployees.map((uid) => {
				var user = this.getUser(uid)
				var employeePolicy = this.getEmployeePolicyForUser(uid)
				var healthInsurance = employeePolicy?.insurances?.[0]?.healthInsurance?.insuranceType ?? ''
				if (healthInsurance == 'expanded') {
					healthInsurance = 'udvidet'
				}
				return {
					'CVR': this.companyCvr,
					'Gruppe': this.getGroupName(user),
					'Fornavn': user?.firstName,
					'Efternavn': user?.lastName,
					'Primær email': user?.email,
					'Sekundær email': user?.emailSecondary,
					'Telefon': user?.phone,
					'CPR-nr': user?.cpr,
					'Stilling': employeePolicy?.employeeCondition.position,
					'Ansættelsedato': this.getEmploymentDateShort(user),
					'Pension startdato': this.getPensionDateShort(user),
					'Sundhedsforsikring startdato': this.getHealthInsuranceDateShort(user),
					'Grundløn pr mnd': employeePolicy?.employeeCondition.salary,
					'Tillægsløn pr mnd': employeePolicy?.employeeCondition.supplementarySalary,
					'Total pensionbidrag': this.numberFormat(this.getTotalPension(user)),
					'Total Firmaandel': this.numberFormat(this.getTotalCompanyShare(user)),
					'Total Medarbejderandel': this.numberFormat(this.getTotalEmployeeShare(user)),
					'Obligatorisk firmaandel': this.numberFormat(this.getMandatoryCompanyShare(user)),
					'Obigatorisk medarbejderandel': this.numberFormat(this.getMandatoryEmployeeShare(user)),
					'Frivillig firmaandel': this.numberFormat(this.getVoluntaryCompanyShare(user)),
					'Frivillig medarbejderandel': this.numberFormat(this.getVoluntaryEmployeeShare(user)),
					'Død (%)': employeePolicy?.insurances?.[0]?.deathSum?.value ?? '',
					'Tab af arbejdsevne (%)': employeePolicy?.insurances?.[0]?.workAbility?.value ?? '',
					'Visse kritiske sygdomme sum': employeePolicy?.insurances?.[0]?.criticalDisease?.value ?? '',
					'Visse kritiske sygdomme børn sum': employeePolicy?.insurances?.[0]?.criticalDiseaseChild?.value ?? '',
					'Børnerente (%)': employeePolicy?.insurances?.[0]?.childPension?.value ?? '',
					'Invalidesum': employeePolicy?.insurances?.[0]?.invalideSum?.value ?? '',
					'Depotværdi': employeePolicy?.individualCondition?.custodianValue ?? '',
					'Sundhedsforsikring': healthInsurance,
					'Kommentar': employeePolicy?.comment,
				}
			})
			var excelData = [{ name: 'Medarbejdere', data: data }]
			return excelData
		},
		getExcelFormattedDataForCompanyAdmin() {
			//CVR	Fornavn	Efternavn	Primær email (bruges til login)	Sekundær email	Landekode	Telefon	CPR-nr.	Stilling	Grundløn pr mnd	Tillægsløn pr mnd	Fri firmaandel (%)	Fri medarbejderandel (%)	Død (%)	Tab af arbejdsevne (%)	Visse kritiske sygdomme sum	Visse kritiske sygdomme børn sum	Børnerente (%)	Depotværdi	Sundhedsforsikring
			var data = this.companyEmployees.map((uid) => {
				var user = this.getUser(uid)
				var employeePolicy = this.getEmployeePolicyForUser(uid)
				return {
					'CVR': this.companyCvr,
					'Gruppe': this.getGroupName(user),
					'Fornavn': user?.firstName,
					'Efternavn': user?.lastName,
					'Primær email': user?.email,
					'Sekundær email': user?.emailSecondary,
					'Telefon': user?.phone,
					'CPR-nr': user?.cpr,
					'Ansættelsedato': this.getEmploymentDateShort(user),
					'Pension startdato': this.getPensionDateShort(user),
					'Sundhedsforsikring startdato': this.getHealthInsuranceDateShort(user),
					'Stilling': employeePolicy?.employeeCondition.position,
					'Grundløn pr mnd': employeePolicy?.employeeCondition?.salaryVisible ? employeePolicy?.employeeCondition.salary : '-',
					'Tillægsløn pr mnd': employeePolicy?.employeeCondition?.salaryVisible ? employeePolicy?.employeeCondition.supplementarySalary : '-',
					'Total pensionbidrag': this.numberFormat(this.getTotalPension(user)),
					'Total firmaandel': this.numberFormat(this.getTotalCompanyShare(user)),
					'Total medarbejderandel': this.numberFormat(this.getTotalEmployeeShare(user)),
					'Obligatorisk firmaandel': this.numberFormat(this.getMandatoryCompanyShare(user)),
					'Obigatorisk medarbejderandel': this.numberFormat(this.getMandatoryEmployeeShare(user)),
					'Frivillig firmaandel': this.numberFormat(this.getVoluntaryCompanyShare(user)),
					'Frivillig medarbejderandel': this.numberFormat(this.getVoluntaryEmployeeShare(user)),
				}
			})
			var excelData = [{ name: 'Medarbejdere', data: data }]
			return excelData
		},
		companyPensions() {
			return this.company.pensions
		},
		companyInsurances() {
			return this.company.insurances
		},
		companyEmployees() {
			return this.company?.employees ?? []
		},
		users() {
			return this.$store.getters['companyVuex/employees']
		},
		companyEmployeesFormatted() {
			var employees = this.companyEmployees
			employees =
				employees
					?.map((employeeId) => {
						var user = this.getUser(employeeId)
						var groupName = this.getGroupName(user)
						var position = this.getPosition(user)
						var salary = this.numberFormat(this.getSalaryForTheUser(user)) + ' kr.'
						var isResigned = this.employeeIsResigned(user)
						var rowVariant = isResigned ? 'danger' : ''
						var healthInsuranceStartDate = this.getHealthInsuranceDateShort(user)
						var pensionStartDate = this.getPensionDateShort(user)
						var employmentDate = this.getEmploymentDateShort(user)
						var policyId = this.getEmployeePolicyForUser(employeeId)?.id
						return {
							user: this.getUser(employeeId),
							groupName: groupName,
							position: position,
							healthInsuranceStartDate: healthInsuranceStartDate,
							pensionStartDate: pensionStartDate,
							employmentDate: employmentDate,
							salary: salary,
							_rowVariant: rowVariant,
							isActive: !isResigned,
							policyId: policyId,
						}
					})
					.sort(function (a, b) {
						if (a.user?.firstName?.toLowerCase() < b.user?.firstName?.toLowerCase()) {
							return -1
						}
						if (a.user?.firstName?.toLowerCase() > b.user?.firstName?.toLowerCase()) {
							return 1
						}
						return 0
					}) || []
			return employees.filter((obj) => {
				if (this.globalfilter == '') {
					return true
				} else {
					return Object.keys(obj).some((key) => {
						try {
							var filtered = JSON.stringify(obj[key]).toLowerCase()
							return filtered.includes(this.globalfilter.toLowerCase())
						} catch (err) {
							console.log(obj)
							console.log(key)
						}
					})
				}
			})
		},
		companyId() {
			return this.$route.params.companyId
		},
		companyCvr() {
			return this.company.companyInfo.cvr
		},
		company() {
			var company = this.$store.getters['companyVuex/company']
			return company
		},
		employeePolicies() {
			return this.$store.getters['companyVuex/employeePolicies']
		},
		insurances() {
			return this.$store.getters['companyVuex/insurances']
		},
		pensions() {
			return this.$store.getters['companyVuex/pensions']
		},
		isBusy() {
			return !this.$store.getters['companyVuex/employeeListIsReady']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		isAdmin() {
			return this.$store.getters['auth/isAdmin']
		},
		isAdvisor() {
			return this.$store.getters['auth/isAdvisor']
		},
	},
}
</script>

<style lang="scss"></style>
